import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Button} from "@material-ui/core";

const LoginButton = () => {
    const {loginWithRedirect} = useAuth0();
    return (
        <Button
            color={"inherit"}
            className="btn btn-primary btn-block"
            onClick={() => loginWithRedirect({
                appState: {
                    returnTo: '/academy/dashboard'
                }
            })}
        >
            LOGIN
        </Button>
    );
};

export default LoginButton;
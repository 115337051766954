import React, {Fragment} from 'react';
import '../courseComponents.css';
import {Player} from 'video-react';
import '../../../utils/video-react.css';
import {Button} from "@material-ui/core";

const CourseComponentUser = (props) => {
    let render = props.cont.content.map((ig, index) => {
        let returnValue;
        switch (ig.type) {
            case "headline1":
                returnValue = (<h2 key={index}>{ig.body}</h2>)
                break;
            case "text":
                returnValue = (
                    <p key={index}>{ig.body}</p>)

                // falls expression mit value2 übereinstimmt
                break;
            case "image":
                returnValue = (<img src={ig.body.urlImage} style={{width: "100%"}} alt={"Auf Bild warten..."}/>)
                break;
            case "video":
                returnValue = (<Player src={ig.body.urlVideo} style={{width: "100%"}}/>)
                break;
            case "pdf":
                returnValue = (
                    <Button variant={"contained"} onClick={() => window.open(ig.body.urlPdf)}>PDF öffnen</Button>);
                break;
            default:
                // Anweisungen werden ausgeführt,
                // falls keine der case-Klauseln mit expression übereinstimmt
                break;
        }
        return returnValue;
    })

    return (
        <Fragment>
            <h1>{props.cont.name}</h1>
            {render}

        </Fragment>
    );
};

export default CourseComponentUser;
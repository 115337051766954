import React, {Fragment, useState} from 'react';

const CourseComponentText = (props) => {
    const [status, setStatus] = useState(false);
    const [text, setText] = useState(props.text);

    const paragraph = props.text.split('\n').map((lines, index) => {
        return (<Fragment key={index}>{lines}<br/></Fragment>)
    })

    const submitChange = () => {
        props.changeContent(props.nr, text);
        setStatus(!status);
    }

    let renderItem;
    if (status) {
        renderItem = (
            <Fragment>
                <textarea className={"course-text"} name="textarea" rows="10" cols="30"
                          onChange={event => setText(event.target.value)} value={text}> </textarea>
                <button onClick={submitChange} className={"course-button-small"}>speichern</button>
            </Fragment>);

    } else {
        renderItem = (<p className={"course-text"} onClick={() => setStatus(!status)}>{paragraph}</p>)
    }

    return (
        <Fragment>{renderItem}</Fragment>
    );
};

export default CourseComponentText;

import React, {Fragment} from 'react';
import List from "@material-ui/core/List";
import NavItem from "../navItem/navItem";
import {makeStyles} from "@material-ui/core/styles";
import {useAuth0} from "@auth0/auth0-react";

const useStyles = makeStyles({
    list: {
        width: 250
    },
    fullList: {
        width: 'auto',
    }

});


const MenuListBig = () => {
    const classes = useStyles(); //Get styling from function above
    const {user} = useAuth0();

    const renderList = () => {
        const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
        if (user[rolePath].indexOf("Admin") > -1 || user[rolePath].indexOf("UserAll") > -1) {
            return (
                <Fragment>
                    <NavItem text={"Rezepte"} target={'/academy/recipes'} icon={"recipe"}/>
                    <NavItem text={"Akademie"} target={'/academy/courses'} icon={"academy"}/>
                    <NavItem text={"Ernährungsvorträge"} target={'/academy/talk'} icon={"spoon"}/>
                    <NavItem text={"Sport"} target={'/academy/sports'} icon={"fitness"}/>
                    <NavItem text={"Entspannung"} target={'/academy/relax'} icon={"relax"}/>
                </Fragment>
            )
        } else if (user[rolePath].indexOf("UserNutrition") > -1) {
            return (
                <Fragment>
                    <NavItem text={"Ernährungsvorträge"} target={'/academy/talk'} icon={"spoon"}/>
                    <NavItem text={"Akademie"} target={'/academy/courses'} icon={"academy"}/>
                </Fragment>
            )
        } else if (user[rolePath].indexOf("UserBasic") > -1) {
            return (
                <Fragment>
                    <NavItem text={"Rezepte"} target={'/academy/recipes'} icon={"recipe"}/>
                    <NavItem text={"Sport"} target={'/academy/sports'} icon={"fitness"}/>
                    <NavItem text={"Entspannung"} target={'/academy/relax'} icon={"relax"}/>
                </Fragment>
            )
        }
    }

    //Render component
    return (
        <div
            className={classes.list}
            role="presentation"
        >
            <List>
                <NavItem text={"Home"} target={'/academy/dashboard'} icon={"home"}/>
                {renderList()}
            </List>

        </div>
    );

};

export default MenuListBig;

import React from 'react';
import './spinner.css';

const Spinner = (props) => {


    return (
        <div className={"loader"} style={{display: props.display}}>
        </div>
    );
};

export default Spinner;

import React, {useState} from 'react';
import './modal.css'
import axios from "../../axios";
import {useAuth0} from "@auth0/auth0-react";
import {withRouter} from "react-router-dom";
import defaultPic from "../../assets/images/icons8-camera.svg";

const ModalNewCourse = (props) => {
    const [newCourse, setNewCourse] = useState("");
    const [blobImageName, setBlobImageName] = useState("");
    const [imageUrl, setImageUrl] = useState(defaultPic);
    const {getAccessTokenSilently} = useAuth0();

    const displayModal = () => {
        if (props.display === true) {
            return "block";
        } else {
            return "none"
        }
    }

    const submitNewCourse = async () => {
        const token = await getAccessTokenSilently();

        const data = {
            name: newCourse,
            image: blobImageName
        }

        axios.post('/courses/' + props.id + '/lections', data, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                props.history.push('/academy/courses/' + props.id + '/' + response.data.lecture._id);
            })
            .catch(err => console.log(err))

    }

    const onChangeImage = async (event) => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();
        formData.append('files', event.target.files[0]);
        axios.post(`/assets/image`, formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setImageUrl(response.data[0].url);
                setBlobImageName(response.data[0].blobName);
            })
            .catch(err => console.log(err));
    };

    return (
        <div className={"modal"} style={{display: displayModal()}}>
            <div className={"modal-content"}>
                <h3 className={"modal-head"}>{props.title}</h3>
                <div style={{width: "100%", display: "flex", alignContent: "center"}}>
                    <img src={imageUrl} alt={"Bitte bild hochladen"} style={{margin: "auto", maxWidth: "60&"}}/>
                </div>
                <input type={"file"} style={{marginBottom: "1em"}} onChange={onChangeImage}/>
                <input type={"text"} onChange={event => setNewCourse(event.target.value)} value={newCourse}
                       className={"input-text-normal"}/>
                <button className={"modal-btn button-normal"}
                        onClick={submitNewCourse}>{props.btnContent}</button>
                <button className={"modal-btn-decline button-normal"}
                        onClick={() => props.func2()}>{props.btnContent2}</button>
            </div>

        </div>
    );
};

export default withRouter(ModalNewCourse);

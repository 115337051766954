import React, {useEffect, useState} from 'react';
import {useParams, withRouter} from 'react-router-dom';
import CourseComponentUser from "../../../components/courseComponents/courseComponentUser/courseComponentUser";
import axios from "../../../axios";
import {Button, Container} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";


const CourseDetail = (props) => {
    const {getAccessTokenSilently} = useAuth0();
    const {user} = useAuth0();
    const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
    const userRole = user[rolePath][0];

    let {id} = useParams();
    let {coursePartId} = useParams();

    const [lectureDetails, setLectureDetails] = useState();

    useEffect(async () => {
        const token = await getAccessTokenSilently();

        axios.get('/courses/' + id + '/lections/' + coursePartId, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setLectureDetails(response.data.lectures)
            })
            .catch(err => console.log(err))
    }, [])

    const renderBody = () => {
        if (lectureDetails != null) {
            return (<CourseComponentUser cont={lectureDetails}/>)
        } else {
            return <p>Waiting for backend...</p>
        }
    }

    const renderEditButton = () => {
        if (userRole === 'Admin') {
            return (
                <Button variant={"contained"} size={"large"} color={"primary"} onClick={() => {
                    props.history.push("/academy/courses/" + id + "/" + coursePartId + "/edit")
                }}>Edit
                </Button>
            )
        }
    }

    return (
        <Container maxWidth={"xl"}>
            {renderBody()}
            <br/>
            {renderEditButton()}
        </Container>
    );
};

export default withRouter(CourseDetail);

import React, {useEffect, useState} from 'react';
import {useParams, withRouter} from 'react-router-dom';
import {Grid} from "@material-ui/core";
import IngredientsList from "../../../components/recipe/ingredientsList/ingredientsList";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import axios from "../../../axios";
import {useAuth0} from "@auth0/auth0-react";
import RecipeImageComponent from "../../../components/recipeComponents/recipeImageComponent/recipeImageComponent";
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";


const RecipeEdit = (props) => {
    const [ingredients, setIngredients] = useState([]);
    const [ingredientsName, setIngredientsName] = useState("");
    const [ingredientsQuantity, setIngredientsQuantity] = useState("");
    const [recipeName, setRecipeName] = useState("");
    const [preparation, setPreparation] = useState("");
    const [alternative, setAlternative] = useState("");
    const [images, setImages] = useState([]);
    const [active, setActive] = useState(false);
    const [renderTriger, setRenderTriger] = useState(0);
    const [recipe, setRecipe] = useState();
    const {getAccessTokenSilently} = useAuth0();
    const [uploadSuccess, setUploadSucess] = useState(false);

    let {id} = useParams();

    useEffect(async () => {
        const token = await getAccessTokenSilently();

        axios.get('/recipes/' + id, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setRecipe(response.data.result);
                setIngredients(response.data.result.ingredients);
                setRecipeName(response.data.result.name);
                setPreparation(response.data.result.preparation);
                setAlternative(response.data.result.variant);
                setActive(response.data.result.activ);
                setImages(response.data.result.images);
            })
            .catch(err => console.log(err)) //Throw error to console
    }, [])

    const addToIngredients = (iQty, iName) => {
        let ingredientsList = ingredients;
        ingredientsList.push({qty: iQty, desc: iName});
        setIngredients(ingredientsList);
        setRenderTriger(renderTriger + 1);
    }

    const handleChange = (event) => {
        setActive(event.target.checked);
    };

    const sendToServer = async () => {
        const token = await getAccessTokenSilently();

        let imageBlobData = [];

        for (let i = 0; i < images.length; i++) {
            const data = {blobImage: images[i].blobImage};
            imageBlobData.push(data);
        }

        const data = {
            name: recipeName,
            ingredients: ingredients,
            preparation: preparation,
            variant: alternative,
            activ: active,
            images: imageBlobData
        }
        axios.patch('/recipes/' + id, data, {headers: {Authorization: `Bearer ${token}`}})
            .then(function (response) {
                //console.log(response);
                props.history.push(`/academy/recipes/${id}`)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const uploadImage = async (event) => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();
        formData.append('files', event.target.files[0]);

        axios.post("/assets/image", formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                let imageData = {
                    blobImage: response.data[0].blobName,
                    imageUrl: response.data[0].url
                }
                images.push(imageData);
                setUploadSucess(true);
            })
            .catch(err => console.log(err));
    };


    return (
        <div>
            <h1>Dein neues Rezept</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Name des Rezepts</label>
                    <input type={"text"} className={"input-text-normal"} value={recipeName}
                           onChange={event => setRecipeName(event.target.value)}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Zutaten</label>
                    <IngredientsList ingList={ingredients}/>
                    <input type={"text"} value={ingredientsQuantity}
                           onChange={event => setIngredientsQuantity(event.target.value)}/>
                    <input type={"text"} value={ingredientsName} onChange={event => {
                        setIngredientsName(event.target.value)
                    }}/>
                    <button onClick={() => addToIngredients(ingredientsQuantity, ingredientsName)}>Click</button>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Zubereitung</label>
                    <textarea rows="10" cols="30" className={"input-text-normal"} value={preparation}
                              onChange={event => setPreparation(event.target.value)}>Zubereitung</textarea>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Abwandlung</label>
                    <textarea rows="10" cols="30" className={"input-text-normal"} value={alternative}
                              onChange={event => setAlternative(event.target.value)}>Abwandlung</textarea>
                </Grid>
                <Grid item xs={12} md={3}>
                    <label className={"input-label"}>Name des Rezepts</label>
                    <input type={"file"} className={"input-file-normal"} style={{width: "95%"}}
                           onChange={event => uploadImage(event)}/>
                    {uploadSuccess ? <CheckCircleOutlined/> : ""}
                </Grid>
                <Grid item xs={12} md={3}>
                    <div>
                        <label className={"input-label"}>Optionen</label>
                        <FormControlLabel
                            control={<Switch checked={active} color={"primary"} onChange={handleChange}/>}
                            label="Rezept aktiv"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div>
                        <button className={"button-normal"} onClick={sendToServer}>Änderungen übernehmen</button>
                    </div>
                </Grid>
            </Grid>
            <RecipeImageComponent images={images}/>
        </div>
    );
};

export default withRouter(RecipeEdit);

import React, {useEffect, useState} from 'react';
import {useParams, withRouter} from "react-router-dom";
import RecipeDetail from "../../containers/academy/recipeDetail/recipeDetail";
import axios from "../../axios";
import {Button} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";


const Recipe = (props) => {
    let {id} = useParams();
    const [recipe, setRecipe] = useState();
    const {getAccessTokenSilently} = useAuth0();
    const {user} = useAuth0();
    const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
    const userRole = user[rolePath][0];

    useEffect(async () => {
        const token = await getAccessTokenSilently();

        axios.get('/recipes/' + id, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setRecipe(response.data.result);
                //console.log(response);
            })
            .catch(err => console.log(err)) //Throw error to console
    }, [])

    let renderRecipe = () => {
        if (recipe != null) {
            return (<RecipeDetail recipe={recipe}/>);

        } else {
            return (<p>Waiting for server...</p>);
        }
    }


    return (
        <div>
            {renderRecipe()}
            {userRole === 'Admin' ? <Button variant={"contained"} size={"large"} color={"primary"}
                                            onClick={() => props.history.push("/academy/recipes/" + id + "/edit")}>Ändern </Button> : ''}
        </div>
    );
};

export default withRouter(Recipe);

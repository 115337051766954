import React, {Fragment, useState} from 'react';
import {Button, CircularProgress, Grid} from "@material-ui/core";
import axios from "../../../axios";
import {useAuth0} from "@auth0/auth0-react";
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';


const SportsNew = (props) => {
    const {getAccessTokenSilently} = useAuth0();

    const [name, setName] = useState("");
    const [category, setCategory] = useState("workout");
    const [imageUrl, setImageUrl] = useState("");
    const [imageFile, setImageFile] = useState();
    const [videoUrl, setVideoUrl] = useState("");
    const [statusUpload, setStatusUpload] = useState("empty"); //emtpy, gotContent, uploading, uploaded
    const [videoFile, setVideoFile] = useState();
    const [audioFile, setAudioFile] = useState();
    const [audioUrl, setAudioUrl] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [longDescription, setLongDescription] = useState("");
    const [blobNameImage, setBlobNameImage] = useState("");
    const [blobNameVideo, setBlobNameVideo] = useState("");
    const [blobNameAudio, setBlobNameAudio] = useState("");

    const sendDataToServer = async () => {
        const token = await getAccessTokenSilently();

        let data = {};

        if (category === 'relax') {
            data = {
                name: name,
                sub: category,
                blobAudio: blobNameAudio,
                blobImage: blobNameImage,
                longDescription: longDescription,
                shortDescription: shortDescription,
                active: false
            }
        } else {
            data = {
                name: name,
                sub: category,
                blobVideo: blobNameVideo,
                blobImage: blobNameImage,
                longDescription: longDescription,
                shortDescription: shortDescription,
                active: false
            }
        }


        axios.post("/sports", data, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                props.history.push(`/academy/sports/${response.data._id}`)
            })
            .catch(err => {
                console.log(err);
            });
    };

    const showVideoIcon = () => {
        switch (statusUpload) {
            case "empty":

                if (category === 'relax') {
                    return (<Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('video')}>Hochladen</Button>)
                } else {
                    return (<Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('audio')}>Hochladen</Button>)
                }
                break;
            case "gotContent":
                if (category === 'relax') {
                    return (<Button variant={"contained"} color={"secondary"} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('audio')}>Hochladen</Button>)
                } else {
                    return (<Button variant={"contained"} color={"secondary"} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('video')}>Hochladen</Button>)
                }
                break;
            case "uploading":
                if (category === 'relax') {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('audio')}>Hochladen</Button>
                            <CircularProgress size={24}/>
                        </Fragment>)
                } else {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('video')}>Hochladen</Button>
                            <CircularProgress size={24}/>
                        </Fragment>)
                }
                break;
            case "uploaded":
                if (category === 'relax') {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('audio')}>Hochladen</Button>
                            <CheckCircleOutlined color={"success"} fontSize={"medium"} style={{marginLeft: "0.5em"}}/>
                        </Fragment>)
                } else {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('video')}>Hochladen</Button>
                            <CheckCircleOutlined color={"success"} fontSize={"medium"} style={{marginLeft: "0.5em"}}/>
                        </Fragment>)
                }
                break;
        }

    }

    const onChangeImage = (event) => {
        setImageFile(event.target.files[0]);
    };

    const onChangeVideo = (event) => {
        setVideoFile(event.target.files[0]);
        setStatusUpload("gotContent");
    };

    const onChangeAudio = (event) => {
        setAudioFile(event.target.files[0]);
        setStatusUpload("gotContent");
    }

    const uploadeFile = async (target) => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();


        if (target === 'video') {
            if (videoFile != null) {
                formData.append('files', videoFile);
                setStatusUpload("uploading");
            } else {
                console.log("no video available");
                return;
            }
        } else if (target === 'image') {
            if (imageFile != null) {
                formData.append('files', imageFile);
            } else {
                console.log("no image available");
                return
            }
        } else if (target === 'audio') {
            if (audioFile != null) {
                formData.append('files', audioFile);
                setStatusUpload("uploading");
            } else {
                console.log("no audio available");
                return
            }
        } else {
            //do nothing
        }

        axios.post(`/assets/${target}`, formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                if (target === 'video') {
                    setVideoUrl(response.data.url);
                    setBlobNameVideo(response.data.blobName);
                    setStatusUpload("uploaded");
                } else if (target === 'image') {
                    setImageUrl(response.data[0].url);
                    setBlobNameImage(response.data[0].blobName);
                } else if (target === 'audio') {
                    setAudioUrl(response.data.url);
                    setBlobNameAudio(response.data.blobName);
                    setStatusUpload("uploaded");
                } else {
                    //do nothing
                }
            })
            .catch(err => console.log(err));
    }

    return (
        <div>
            <h1>Dein neuer Sport- / Relaxkurs</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Name</label>
                    <input type={"text"} className={"input-text-normal"} value={name}
                           onChange={event => setName(event.target.value)}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Kategorie</label>
                    <select id="category" name="category" onChange={event => setCategory(event.target.value)}
                            value={category}>
                        <option value="back">Rücken</option>
                        <option value="workout">Workout</option>
                        <option value="bellykiller">Bauchkiller</option>
                        <option value="activebreak">Aktive Pause</option>
                        <option value="relax">Entspannung</option>
                    </select>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Bild</label>
                    <input type={"file"} onChange={onChangeImage}/>
                    {imageFile == null ?
                        <Button variant={"outlined"} color={"secondary"} disabled={true}
                                onClick={() => uploadeFile('image')}>Hochladen</Button> :
                        <Button variant={"contained"} color={"secondary"}
                                onClick={() => uploadeFile('image')}>Hochladen</Button>}
                    {imageUrl === "" ? "" :
                        <CheckCircleOutlined color={"success"} fontSize={"medium"} style={{marginLeft: "1em"}}/>}
                </Grid>
                {category === 'relax' ?
                    <Grid item xs={12} md={12}>
                        <label className={"input-label"}>Audio</label>
                        <input type={"file"} onChange={onChangeAudio}/>
                        {showVideoIcon()}
                    </Grid>
                    :
                    <Grid item xs={12} md={12}>
                        <label className={"input-label"}>Video</label>
                        <input type={"file"} onChange={onChangeVideo}/>
                        {showVideoIcon()}
                    </Grid>}
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Beschreibung</label>
                    <textarea name="longDescription" rows="10" cols="30" className={"input-text-normal"}
                              value={longDescription}
                              onChange={event => setLongDescription(event.target.value)}>Beschreibung</textarea>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Kurzbeschreibung</label>
                    <textarea name="ShortDescription" rows="10" cols="30" className={"input-text-normal"}
                              value={shortDescription}
                              onChange={event => setShortDescription(event.target.value)}>Kurzbeschreibung</textarea>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Button onClick={sendDataToServer}>Speichern</Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default SportsNew;

import React from 'react';
import {Grid} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import './courseLink.css'

const useStyles = makeStyles({
    gridContainer: {
        margin: 0,
        width: "100%",
        backgroundColor: "#f0f0f0",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        marginTop: "1em",
        '&:hover': {
            cursor: "pointer"
        },
        marginBottom: "1em"
    },
    gridItemPic: {
        display: "grid"
    },
    gridItemText: {
        padding: "1em"
    }
});

const CourseLink = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.gridContainer} onClick={() => props.history.push(props.link)}>
            <Grid item xs={12} md={1} className={classes.gridItemPic}>
                <img src={props.src} alt={props.alt} className={"course-link-pic"}/>
            </Grid>
            <Grid item xs={12} md={11} className={classes.gridItemText}>
                <h1>{props.name}</h1>
            </Grid>
        </Grid>
    );
};

export default withRouter(CourseLink);

import React from 'react';
import {Grid} from "@material-ui/core";

const RecipeImageComponent = (props) => {
    console.log(props);

    let renderImages = props.images.map((ig, index) => {
        return (
            <Grid key={index} item xs={12} md={3}>
                <img src={ig.imageUrl} alt={"Bild wird geladen..."} style={{width: "100%"}}/>
            </Grid>
        )
    });

    return (
        <Grid container spacing={3}>
            {renderImages}
        </Grid>
    );
};

export default RecipeImageComponent;

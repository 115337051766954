import React from 'react';
import {Grid} from "@material-ui/core";
import './footer.css';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <Grid container spacing={3}
              style={{backgroundColor: "#2b6ca3", color: "white", width: "calc(100% + 16px)", margin: "0 8px 0 -8px"}}>
            <Grid item xs={12} md={4}>
                <p className={"text-footer"}>
                    Tel: +49 (0) 157 / 342 749 97 <br/>
                    Mail: kontakt@m-einklang.de
                </p>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={"link-footer"}>
                    <Link to={"/impressum"}>Impressum</Link>
                    <Link to={"/privacy"}>Datenschutz</Link>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <p className={"text-footer-right"}>
                    Copyright 2021 © M-Einklang.de
                </p>
            </Grid>
        </Grid>
    );
};

export default Footer;

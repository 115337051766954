import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {withRouter} from 'react-router-dom';

const RecipeTableRow = (props) => {

    const clickHandler = (id) => {
        props.history.push("/academy/recipes/" + id)
    }

    return (
        <tr>
            <td className={"table-row-element"}>{props.name}</td>
            <td className={"table-row-element"}>{props.kcal}</td>
            <td className={"table-row-element"}>
                <ArrowForwardIosIcon onClick={() => clickHandler(props.id)}/>
            </td>
        </tr>
    );
};

export default withRouter(RecipeTableRow);

import React, {Fragment, useEffect} from 'react';
import Navbar from "../../components/navigation/Navbar/navbar";
import SideBar from "../../components/navigation/sideBar/sideBar";
import Token from "../../token/token";
import SideBarMobil from "../../components/navigation/sideBar/sideBarMobil";


const Navigation = () => {
    const [open, setOpen] = React.useState(false);

    const changeDrawerOpoen = () => {
        setOpen(!open);
    }

    return (
        <Fragment>
            <Navbar funcOpen={changeDrawerOpoen}/>
            <SideBarMobil open={open} toggle={changeDrawerOpoen}/>
        </Fragment>
    );
};

//<SideBar open={open}/>

export default Navigation;

import React, {useState} from 'react';
import {Grid} from "@material-ui/core";
import IngredientsList from "../../../components/recipe/ingredientsList/ingredientsList";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {render} from "@testing-library/react";
import {withRouter} from 'react-router-dom';
import axios from "../../../axios";
import {useAuth0} from "@auth0/auth0-react";

const RecipeNew = (props) => {
    const [ingredients, setIngredients] = useState([]);
    const [ingredientsName, setIngredientsName] = useState("");
    const [ingredientsQuantity, setIngredientsQuantity] = useState("");
    const [recipeName, setRecipeName] = useState("");
    const [preparation, setPreparation] = useState("");
    const [alternative, setAlternative] = useState("");
    const [active, setActive] = useState(false);
    const [image, setImage] = useState();
    const [renderTriger, setRenderTriger] = useState(0);
    const {getAccessTokenSilently} = useAuth0();

    const category = props.location.search.split('=')


    const addToIngredients = (iQty, iName) => {
        let ingredientsList = ingredients;
        ingredientsList.push({qty: iQty, desc: iName});
        setIngredients(ingredientsList);
        setRenderTriger(renderTriger + 1);
    }

    const changeActive = () => {
        setActive(!active);
    }

    const sendToServer = async () => {
        const token = await getAccessTokenSilently();
        const data = {
            name: recipeName,
            ingredients: ingredients,
            preparation: preparation,
            variant: alternative,
            pictureUrl: "empty",
            active: active
        }
        axios.post('/recipes/' + category[1], data, {headers: {Authorization: `Bearer ${token}`}})
            .then(function (response) {
                console.log(response); //TODO: Meldungen hinzufügen
            })
            .catch(function (error) {
                console.log(error);
            })
        console.log(data);
    }

    return (
        <div>
            <h1>Dein neues Rezept</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Name des Rezepts</label>
                    <input type={"text"} className={"input-text-normal"} value={recipeName}
                           onChange={event => setRecipeName(event.target.value)}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Zutaten</label>
                    <IngredientsList ingList={ingredients}/>
                    <input type={"text"} value={ingredientsQuantity}
                           onChange={event => setIngredientsQuantity(event.target.value)}/>
                    <input type={"text"} value={ingredientsName} onChange={event => {
                        setIngredientsName(event.target.value)
                    }}/>
                    <button onClick={() => addToIngredients(ingredientsQuantity, ingredientsName)}>Click</button>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Zubereitung</label>
                    <textarea rows="10" cols="30" className={"input-text-normal"} value={preparation}
                              onChange={event => setPreparation(event.target.value)}>Zubereitung</textarea>
                </Grid>
                <Grid item xs={12} md={12}>
                    <label className={"input-label"}>Abwandlung</label>
                    <textarea rows="10" cols="30" className={"input-text-normal"} value={alternative}
                              onChange={event => setAlternative(event.target.value)}>Abwandlung</textarea>
                </Grid>
                <Grid item xs={12} md={3}>
                    <label className={"input-label"}>Name des Rezepts</label>
                    <input type={"file"} className={"input-file-normal"}
                           onChange={event => setImage(event.target.files[0])}/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div>
                        <label className={"input-label"}>Optionen</label>
                        <FormControlLabel
                            control={<Switch checked={active} color={"primary"} onChange={changeActive}/>}
                            label="Rezept aktiv"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={3}>
                    <img alt={"test"} src={image}/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div>
                        <button className={"button-normal"} onClick={sendToServer}>ANLEGEN</button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(RecipeNew);

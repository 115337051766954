import React, {Fragment, useEffect, useState} from 'react';
import CourseComponentSummary from "../../../components/courseComponents/courseComponentSummary/courseComponentSummary";
import {useParams, withRouter} from "react-router-dom";
import '../../../components/courseComponents/courseComponents.css';
import axios from "../../../axios";
import {useAuth0} from "@auth0/auth0-react";
import {Button, CircularProgress, Container} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {Player} from 'video-react';
import '../../../utils/video-react.css';
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";

const useStyles = makeStyles({
    button: {
        marginRight: "1em"
    }
});

const CourseDetailEdit = (props) => {
    let {id} = useParams();
    const {getAccessTokenSilently} = useAuth0();
    let {coursePartId} = useParams();
    const classes = useStyles();

    const [content, setContent] = useState([]);
    const [rerender, setRerender] = useState(0);
    const [lectureName, setLectureName] = useState("");

    const [showTextArea, setShowTextArea] = useState(false);
    const [showHeadline, setShowHeadline] = useState(false);
    const [showhImage, setShowhImage] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showPdf, setShowPdf] = useState(false);
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState();
    const [blobImage, setBlobImage] = useState("");
    const [blobVideo, setBlobVideo] = useState("");
    const [blobPdf, setBlobPdf] = useState("");
    const [statusUpload, setStatusUpload] = useState("empty"); //emtpy, gotContent, uploading, uploaded

    useEffect(async () => {
        const token = await getAccessTokenSilently();

        axios.get('/courses/' + id + '/lections/' + coursePartId, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setContent(response.data.lectures.content);
                setLectureName(response.data.lectures.name);
            })
            .catch(err => console.log(err))
    }, [])

    const [text, setText] = useState("");

    const addToContent = (tp, bdy) => {
        let inhalt = content;
        let data = {
            type: tp,
            body: bdy
        }
        inhalt.push(data);
        setContent(inhalt);
        setText("");
        setShowHeadline(false);
        setShowTextArea(false);
        setShowhImage(false);
        setShowVideo(false);
        setShowPdf(false);
        setStatusUpload("empty");
        setRerender(rerender + 1);
        setStatusUpload("empty");
    };

    const changeContent = (ind, text) => {
        let inhalt = content;
        inhalt[ind].body = text;
        setContent(inhalt);
        setRerender(rerender + 1);
    };

    const submitCourse = async () => {
        const token = await getAccessTokenSilently();

        const data = {
            content: content
        }

        for (let i = 0; i < data.content.length; i++) {
            if (data.content[i].type === 'image') {
                data.content[i].body = data.content[i].body.blobImage;
            } else if (data.content[i].type === 'video') {
                data.content[i].body = data.content[i].body.blobVideo;
            } else if (data.content[i].type === 'pdf') {
                data.content[i].body = data.content[i].body.blobPdf;
            }
        }

        axios.patch('/courses/' + id + '/lections/' + coursePartId, data, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                props.history.push('/academy/courses/' + id + '/' + coursePartId);
            })
            .catch(err => console.log(err))
    };

    const onChangeImage = (event) => {
        setFile(event.target.files[0]);
    };

    const onChangeVideo = (event) => {
        setFile(event.target.files[0]);
        setStatusUpload("gotContent");
    };

    const onChangePdf = (event) => {
        setFile(event.target.files[0]);
        setStatusUpload("gotContent");
    }

    const submitImage = async () => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();
        formData.append('files', file);

        axios.post("/assets/image", formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setFileUrl(response.data[0].url);
                setBlobImage(response.data[0].blobName);
            })
            .catch(err => console.log(err));
    };

    const submitVideo = async () => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();
        formData.append('files', file);
        setStatusUpload("uploading");
        axios.post("/assets/video", formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setFileUrl(response.data.url);
                setBlobVideo(response.data.blobName);
                setStatusUpload("uploaded");
            })
            .catch(err => console.log(err));
    };

    const submitPdf = async () => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();
        formData.append('files', file);
        setStatusUpload("uploading");
        axios.post("/assets/pdf", formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setFileUrl(response.data.url);
                setBlobPdf(response.data.blobName);
                setStatusUpload("uploaded");
            })
            .catch(err => console.log(err));
    }

    const showStatusIcon = (target) => {
        switch (statusUpload) {
            case "empty" :
                if (target === 'video') {
                    return (<Button variant={"outlined"} size={"small"} className={classes.button} disabled={true}
                                    onClick={submitVideo}>Hochladen</Button>)
                } else if (target === 'pdf') {
                    return (<Button variant={"outlined"} size={"small"} className={classes.button} disabled={true}
                                    onClick={submitPdf}>Hochladen</Button>)
                }
                break;
            case "gotContent":
                if (target === 'video') {
                    return (<Button variant={"outlined"} size={"small"} className={classes.button} disabled={false}
                                    onClick={submitVideo}>Hochladen</Button>)
                } else if (target === 'pdf') {
                    return (<Button variant={"outlined"} size={"small"} className={classes.button} disabled={false}
                                    onClick={submitPdf}>Hochladen</Button>)
                }
                break;
            case "uploading":
                if (target === 'video') {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} size={"small"} className={classes.button} disabled={true}
                                    onClick={() => addToContent("video", {
                                        urlVideo: fileUrl,
                                        blobVideo: blobVideo
                                    })}>Hinzufügen</Button>
                            <CircularProgress size={24} style={{marginLeft: "0.5em"}}/>
                        </Fragment>)
                } else if (target === 'pdf') {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} size={"small"} className={classes.button} disabled={true}
                                    onClick={() => addToContent("pdf", {
                                        urlPdf: fileUrl,
                                        blobPdf: blobPdf
                                    })}>Hinzufügen</Button>
                            <CircularProgress size={24} style={{marginLeft: "0.5em"}}/>
                        </Fragment>)
                }
                break;
            case "uploaded":
                if (target === 'video') {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} size={"small"} className={classes.button} disabled={false}
                                    onClick={() => addToContent("video", {
                                        urlVideo: fileUrl,
                                        blobVideo: blobVideo
                                    })}>Hinzufügen</Button>
                            <CheckCircleOutlined color={"success"} fontSize={"medium"} style={{marginLeft: "0.5em"}}/>
                        </Fragment>)
                } else if (target === 'pdf') {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} size={"small"} className={classes.button} disabled={false}
                                    onClick={() => addToContent("pdf", {
                                        urlPdf: fileUrl,
                                        blobPdf: blobPdf
                                    })}>Hinzufügen</Button>
                            <CheckCircleOutlined color={"success"} fontSize={"medium"} style={{marginLeft: "0.5em"}}/>
                        </Fragment>)
                }
                break;
        }
    }


    let newItem;
    if (showHeadline) {
        newItem = (
            <Fragment>
                <input className={"course-headline"} type={"text"} value={text}
                       onChange={event => setText(event.target.value)}/>
                <button className={"course-button-small"} onClick={() => addToContent("headline1", text)}>add</button>
            </Fragment>
        )
    } else if (showTextArea) {
        newItem = (
            <Fragment>
                <textarea className={"course-text"} name="message" rows="10" cols="30"
                          onChange={event => setText(event.target.value)}> </textarea>
                <button className={"course-button-small"} onClick={() => addToContent("text", text)}>add</button>
            </Fragment>
        )
    } else if (showhImage) {
        newItem = (
            <Fragment>
                <img src={fileUrl} alt={"Bitte Bild hochladen"} style={{width: "100%"}}/>
                <br/>
                <input type={"file"} style={{marginBottom: "1em"}} onChange={onChangeImage}/>
                <br/>
                <Button variant={"outlined"} size={"small"} className={classes.button}
                        onClick={submitImage}>Hochladen</Button>
                <Button variant={"outlined"} size={"small"} className={classes.button}
                        onClick={() => addToContent("image", {
                            urlImage: fileUrl,
                            blobImage: blobImage
                        })}>Hinzufügen</Button>
            </Fragment>
        )
    } else if (showVideo) {
        newItem = (
            <Fragment>
                <Player src={fileUrl} alt={"Bitte Video hochladen"} style={{width: "100%"}}/>
                <br/>
                <input type={"file"} style={{marginBottom: "1em"}} onChange={onChangeVideo}/>
                <br/>
                {showStatusIcon('video')}
            </Fragment>
        )
    } else if (showPdf) {
        newItem = (
            <Fragment>
                <br/>
                <input type={"file"} style={{marginBottom: "1em"}} onChange={onChangePdf}/>
                {showStatusIcon('pdf')}
            </Fragment>
        )
    } else {
        newItem = (
            <div>
                <Button variant="outlined" size="small" className={classes.button}
                        onClick={() => setShowHeadline(true)}>headline</Button>
                <Button variant="outlined" size="small" className={classes.button}
                        onClick={() => setShowTextArea(true)}>text</Button>
                <Button variant="outlined" size="small" className={classes.button}
                        onClick={() => setShowhImage(true)}>Bild</Button>
                <Button variant="outlined" size="small" className={classes.button}
                        onClick={() => setShowVideo(true)}>Video</Button>
                <Button variant="outlined" size="small" className={classes.button}
                        onClick={() => setShowPdf(true)}>PDF</Button>
                <Button variant="contained" size="small" color={"primary"} className={classes.button}
                        onClick={submitCourse}>Submit</Button>
            </div>
        )
    }

    return (
        <Container maxWidth={"xl"}>
            <h1>{lectureName}</h1>
            {content.length > 0 ? <CourseComponentSummary cont={content} changeContent={changeContent}/> :
                <p>Keine Einträge vorhanden</p>}
            {newItem}
            <br/>
        </Container>
    );
};

export default withRouter(CourseDetailEdit);

import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Button} from "@material-ui/core";

const LogoutButton = () => {
    const {logout} = useAuth0();
    return (
        <Button
            color={"inherit"}
            className="btn btn-danger btn-block"
            onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }
        >
            LOGOUT
        </Button>
    );
};

export default LogoutButton;
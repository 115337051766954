import React, {Fragment} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Redirect, Route, Switch} from "react-router-dom";
import {Hidden} from "@material-ui/core";
import MenuListBig from "../components/navigation/sideBar/menuList/menuListBig";
import SportsNew from "../containers/sports/sportsNew/sportsNew";
import SportsOveriew from "../containers/sports/sportsOverview/sportsOverview";
import SportsOverviewCategory from "../containers/sports/sportsOverviewCategory/sportsOverviewCategory";
import SportsDetail from "../containers/sports/sportsDetail/sportsDetail";

const SportRouting = () => {
    const {user} = useAuth0();
    const renderRouting = () => {
        const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
        if (user[rolePath].indexOf("Admin") > -1) {
            return (
                <Switch>
                    <Route exact path={"/academy/sports/new"} component={SportsNew}/>
                    <Route exact path={"/academy/sports/:id"} component={SportsDetail}/>
                    <Route exact path={"/academy/sports/category/:category"}> <SportsOverviewCategory/> </Route>
                    <Route exact path={"/academy/sports/"}> <SportsOveriew/> </Route>
                </Switch>
            )
        } else if (user[rolePath].indexOf("UserAll") > -1 || user[rolePath].indexOf("UserBasic") > -1) {
            return (
                <Switch>
                    <Route exact path={"/academy/sports/:id"} component={SportsDetail}/>
                    <Route exact path={"/academy/sports/category/:category"}> <SportsOverviewCategory/> </Route>
                    <Route exact path={"/academy/sports/"}> <SportsOveriew/> </Route>
                </Switch>
            )
        } else {
            return (<Redirect to={"/academy/dashboard"}/>)
        }
    }

    return (
        <Fragment>
            <Hidden smDown>
                <div className={"menu-side-container"}>
                    <MenuListBig/>
                </div>
            </Hidden>
            <div className={'wrapper-content'}>

            {renderRouting()}

            </div>
        </Fragment>
    );
};

export default SportRouting;

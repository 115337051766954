import React from 'react';
import {Button, Card, CardActions, CardContent, CardMedia, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router-dom";

const CardMaterialUi = (props) => {

    const btnClick = (link) => {
        props.history.push(link);
    }

    return (
        <Grid item xs={12} sm={6} md={6} lg={3}>
            <Card sx={{maxWidth: 345}}>
                <CardMedia
                    component="img"

                    image={props.img}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.text}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" variant="contained" color={"primary"}
                            onClick={() => btnClick(props.link)}>{props.btnTxt}</Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default withRouter(CardMaterialUi);

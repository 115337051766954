import React from 'react';
import './card.css';

const CardComponent = (props) => {
    return (
        <div className={"card-element"} onClick={props.onClickHandler}>
            <img className={"card-image"} src={props.imgSrc} alt={props.imgAlt}/>
            <h2 className={"card-header"}>{props.head}</h2>
            <p className={"card-text"}>{props.text}</p>
            <div>

            </div>
        </div>
    );
};

export default CardComponent;

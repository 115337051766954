import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, CardActions, CardContent, CardMedia, CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "../../axios";
import {useAuth0} from "@auth0/auth0-react";
import {withRouter} from "react-router-dom";
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import recipe from "../../layouts/recipe/recipe";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EmotionBox = (props) => {

    const [blobImage, setBlobImage] = useState("");
    const [urlImage, setUrlImage] = useState("");
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [btnTxt, setBtnTxt] = useState("");
    const [link, setLink] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [blobPdf, setBlobPdf] = useState("");
    const [urlPdf, setUrlPdf] = useState("");


    const [edit, setEdit] = useState(false);
    const [statusUpload, setStatusUpload] = useState();
    const [statusUploadPdf, setStatusUploadPdf] = useState();
    const [imageFile, setImageFile] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [pdfFile, setPdfFile] = useState();

    const {getAccessTokenSilently} = useAuth0();
    const {user} = useAuth0();
    const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
    const userRole = user[rolePath][0];

    useEffect(async () => {
        const token = await getAccessTokenSilently();

        axios.get('/impulse/' + props.type, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                if (props.type === 'landing') {
                    assignValuesLanding(response.data.impulse);
                } else if (props.type === 'recipe') {
                    assignValuesRecipe(response.data.impulse);
                }
            })
            .catch(err => console.log(err)) //Throw error to console
    }, [])

    const assignValuesLanding = (values) => {
        setBlobImage(values.blobImage);
        setBody(values.body);
        setBtnTxt(values.btnTxt);
        setTitle(values.name);
        setUrlImage(values.urlImage);
        setLink(values.link);
    }

    const assignValuesRecipe = (value) => {
        setBtnTxt("Zum Rezept");
        setTitle("Rezept des Monats");
        setSubtitle(value.name);
        setLink(value.urlPdf);
        setUrlImage(value.urlImage);
        setBlobPdf(value.blobPdf);

    }


    const btnClick = (link, extern) => {
        if (extern) {
            window.open(link, '_blank');
        } else {
            props.history.push(link);
        }
    }

    const showIcon = (icon, file) => {
        switch (icon) {
            case "empty":
                return (<Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                onClick={() => uploadeFile(file)}>Hochladen</Button>)
                break;
            case "gotContent":
                return (<Button variant={"contained"} color={"secondary"} style={{marginLeft: "1em"}}
                                onClick={() => uploadeFile(file)}>Hochladen</Button>)
                break;
            case "uploading":
                return (
                    <Fragment>
                        <Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                onClick={() => uploadeFile(file)}>Hochladen</Button>
                        <CircularProgress size={24}/>
                    </Fragment>)
                break;
            case "uploaded":
                return (
                    <Fragment>
                        <Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                onClick={() => uploadeFile(file)}>Hochladen</Button>
                        <CheckCircleOutlined color={"success"} fontSize={"medium"} style={{marginLeft: "0.5em"}}/>
                    </Fragment>)
                break;
        }
    }


    const uploadeFile = async (type) => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();

        switch (type) {
            case "image":
                formData.append('files', imageFile);
                setStatusUpload("uploading");
                break;
            case "pdf":
                formData.append('files', pdfFile);
                setStatusUploadPdf("uploading");
                break;
            default:
                //do nothing
                break;
        }


        axios.post(`/assets/${type}`, formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                switch (type) {
                    case "image":
                        setUrlImage(response.data[0].url);
                        setBlobImage(response.data[0].blobName);
                        setStatusUpload("uploaded");
                        break;
                    case "pdf":
                        console.log(response.data);
                        setUrlPdf(response.data.url);
                        setBlobPdf(response.data.blobName);
                        setStatusUploadPdf("uploaded");
                        setLink(response.data.url);

                        break;
                    default:
                        //do nothing
                        break;
                }
            })
            .catch(err => console.log(err));
    }

    const onchangeImage = (event) => {
        setImageFile(event.target.files[0]);
        setStatusUpload("gotContent");
    }

    const onchangePdf = (event) => {
        setPdfFile(event.target.files[0]);
        setStatusUploadPdf("gotContent");
    };

    const submitChanges = async () => {
        const token = await getAccessTokenSilently();
        let data = {};
        switch (props.type) {
            case "landing":
                data = {
                    name: title,
                    blobImage: blobImage,
                    body: body,
                    link: link,
                    btnTxt: btnTxt,
                }
                break;
            case "recipe":
                data = {
                    name: subtitle,
                    blobImage: blobImage,
                    blobPdf: blobPdf,
                    body: body,
                    btnTxt: btnTxt,
                }
                break;
            default:
                //do nothing
                break;
        }


        axios.patch(`/impulse/` + props.type, data, {headers: {Authorization: `Bearer ${token}`}})
            .then(result => {
                setEdit(false)
                setOpenSnackbar(true);
            })
            .catch(err => console.log(err));
    }

    const handleClose = () => {
        setOpenSnackbar(false);
    }

    const paragraph = body.split('\n').map((lines, index) => {
        return (<Fragment key={index}>{lines}<br/></Fragment>)
    })

    //Aufbau des Bilds
    return (
        <div style={{marginBottom: "2em", maxHeight: "50%"}}>
            <Card sx={{width: "100%"}}>
                <CardMedia
                    component="img"
                    image={urlImage}
                    alt="green iguana"
                    style={{height: "33.3vh"}}
                />
                <CardContent>
                    {edit === true ?
                        <Fragment>
                            <label className={"input-label"}>Bild</label>
                            <input type={"file"} onChange={onchangeImage}/>
                            {showIcon(statusUpload, "image")}

                            {props.type === 'landing' ?
                                <Fragment>
                                    <label className={"input-label"} style={{}}>Titel</label>
                                    <input className={"input-text-normal"} value={title}
                                           onChange={event => setTitle(event.target.value)}/>
                                </Fragment> : ""}

                            {props.type === 'recipe' ?
                                <Fragment>
                                    <label className={"input-label"}>PDF</label>
                                    <input type={"file"} onChange={onchangePdf}/>
                                    {showIcon(statusUploadPdf, "pdf")}
                                    <label className={"input-label"} style={{}}>Titel</label>
                                    <input className={"input-text-normal"} value={title}
                                           onChange={event => setTitle(event.target.value)} disabled={true}/>
                                    <label className={"input-label"} style={{}}>Name des Rezeptes</label>
                                    <input className={"input-text-normal"} value={subtitle}
                                           onChange={event => setSubtitle(event.target.value)} disabled={false}/>
                                </Fragment> : ""}


                        </Fragment> :
                        <Fragment>
                            <Typography gutterBottom variant="h5" component="div">
                                {title}
                            </Typography>
                            {props.type === 'recipe' ?
                                <Typography gutterBottom variant={"h6"}>{subtitle}</Typography> : ""}
                        </Fragment>}
                    {edit === true ?
                        <Fragment>
                            <label className={"input-label"}>Beschreibung</label>
                            <textarea name="ShortDescription" rows="10" cols="30" className={"input-text-normal"}
                                      value={body}
                                      onChange={event => setBody(event.target.value)}>Beschreibung </textarea>
                        </Fragment> :
                        <Typography variant="body2" color="text.secondary">

                            {paragraph}
                        </Typography>}
                    {edit === true ?
                        <Fragment>
                            <label className={"input-label"}>Link</label>
                            <input className={"input-text-normal"} value={link}
                                   onChange={event => setLink(event.target.value)}/>
                        </Fragment> : ""}
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success">
                            Änderungen wurden gespeichert!
                        </Alert>
                    </Snackbar>
                </CardContent>
                <CardActions>
                    <Button size="small" variant="contained" color={"primary"}
                            onClick={() => btnClick(link, props.externLink)}>{btnTxt}</Button>
                    {userRole === 'Admin' ? <Button
                        onClick={() => setEdit(!edit)}> {edit ? "Bearbeiten beenden" : "Bearbeiten"}</Button> : ""}
                    {edit ?
                        <Button color={"primary"} variant={"outlined"} onClick={submitChanges}>Speichern</Button> : ""}
                </CardActions>
            </Card>
        </div>
    );
};

export default withRouter(EmotionBox);

/*



 */
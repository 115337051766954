import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "../../../axios";
import {useAuth0} from "@auth0/auth0-react";
import '../../../utils/video-react.css';
import {Button, CircularProgress, Container, Grid} from "@material-ui/core";
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ReactAudioPlayer from 'react-audio-player';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ReactPlayer from "react-player";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SportsDetail = () => {
    let {id} = useParams();
    const {user, getAccessTokenSilently} = useAuth0();
    const [course, setCourse] = useState({});
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState("");
    const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
    const role = user[rolePath][0];
    const [videoFile, setVideoFile] = useState();
    const [videoUrl, setVideoUrl] = useState("");
    const [imageFile, setImageFile] = useState();
    const [imageUrl, setImageUrl] = useState("");
    const [audioFile, setAudioFile] = useState();
    const [audioUrl, setAudioUrl] = useState("");
    const [longDescription, setLongDescription] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [active, setActive] = useState(false);
    const [blobNameImage, setBlobNameImage] = useState("");
    const [blobNameVideo, setBlobNameVideo] = useState("");
    const [blobNameAudio, setBlobNameAudio] = useState("");
    const [statusUpload, setStatusUpload] = useState("empty"); //emtpy, gotContent, uploading, uploaded
    const [category, setCategory] = useState("");
    const [urlAudio, setUrlAudio] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const getPermission = () => {
        if (role === 'Admin') {
            return 'admin';
        } else {
            return 'user';
        }
    }

    useEffect(async () => {
        const token = await getAccessTokenSilently();
        const perm = getPermission();

        axios.get(`/sports/${perm}/${id}`, {headers: {Authorization: `Bearer ${token}`}})
            .then(result => {
                assignValues(result.data.result);
                setCourse(result.data.result);
            })
            .catch(err => console.log(err))


    }, [])

    const assignValues = data => {
        setActive(data.active);
        setLongDescription(data.longDescription);
        setShortDescription(data.shortDescription);
        setVideoUrl(data.urlVideo);
        setBlobNameVideo(data.blobVideo);
        setName(data.name);
        setCategory(data.sub);
        setUrlAudio(data.urlAudio);
        setBlobNameAudio(data.blobAudio);
    };

    const uploadeFile = async (target) => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();


        if (target === 'video') {
            if (videoFile != null) {
                formData.append('files', videoFile);
                setStatusUpload("uploading");
            } else {
                console.log("no video available");
                return;
            }
        } else if (target === 'image') {
            if (imageFile != null) {
                formData.append('files', imageFile);
            } else {
                console.log("no image available");
                return
            }
        } else if (target === 'audio') {
            if (audioFile != null) {
                formData.append('files', audioFile);
                setStatusUpload("uploading");
            } else {
                console.log("no audio available");
                return
            }
        } else {
            //do nothing
        }

        axios.post(`/assets/${target}`, formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                if (target === 'video') {
                    setVideoUrl(response.data.url);
                    setBlobNameVideo(response.data.blobName);
                    setStatusUpload("uploaded");
                } else if (target === 'image') {
                    setImageUrl(response.data[0].url);
                    setBlobNameImage(response.data[0].blobName);
                } else if (target === 'audio') {
                    setAudioUrl(response.data.url);
                    setBlobNameAudio(response.data.blobName);
                    setStatusUpload("uploaded");
                } else {
                    //do nothing
                }
            })
            .catch(err => console.log(err));
    }

    const onChangeVideo = (event) => {
        setVideoFile(event.target.files[0]);
        setStatusUpload("gotContent");
    }

    const onChangeAudio = (event) => {
        setAudioFile(event.target.files[0]);
        setStatusUpload("gotContent");
    }


    const submitChanges = async () => {
        const token = await getAccessTokenSilently();
        let data = {
            name: name,
            sub: course.sub,
            blobImage: course.blobImage,
            longDescription: longDescription,
            shortDescription: shortDescription,
            active: active
        }

        if (category === 'relax') {
            data = {...data, blobAudio: blobNameAudio};
        } else {
            data = {...data, blobVideo: blobNameVideo};
        }

        axios.patch(`/sports/${id}`, data, {headers: {Authorization: `Bearer ${token}`}})
            .then(result => {
                setEdit(false)
                setOpenSnackbar(true);
            })
            .catch(err => console.log(err));
    }

    const handleClose = () => {
        setOpenSnackbar(false);
    }

    const showVideoIcon = () => {
        switch (statusUpload) {
            case "empty":

                if (category === 'relax') {
                    return (<Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('video')}>Hochladen</Button>)
                } else {
                    return (<Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('audio')}>Hochladen</Button>)
                }
                break;
            case "gotContent":
                if (category === 'relax') {
                    return (<Button variant={"contained"} color={"secondary"} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('audio')}>Hochladen</Button>)
                } else {
                    return (<Button variant={"contained"} color={"secondary"} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('video')}>Hochladen</Button>)
                }
                break;
            case "uploading":
                if (category === 'relax') {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('audio')}>Hochladen</Button>
                            <CircularProgress size={24}/>
                        </Fragment>)
                } else {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('video')}>Hochladen</Button>
                            <CircularProgress size={24}/>
                        </Fragment>)
                }
                break;
            case "uploaded":
                if (category === 'relax') {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('audio')}>Hochladen</Button>
                            <CheckCircleOutlined color={"success"} fontSize={"medium"} style={{marginLeft: "0.5em"}}/>
                        </Fragment>)
                } else {
                    return (
                        <Fragment>
                            <Button variant={"outlined"} color={"secondary"} disabled={true} style={{marginLeft: "1em"}}
                                    onClick={() => uploadeFile('video')}>Hochladen</Button>
                            <CheckCircleOutlined color={"success"} fontSize={"medium"} style={{marginLeft: "0.5em"}}/>
                        </Fragment>)
                }
                break;
        }

    }
    return (
        <Container maxWidth={"lg"}>
            <h1>{course.name}</h1>
            {edit === true ?
                <Fragment>
                    <label className={"input-label"}>Neuer Name</label>
                    <input type={"text"}
                           onChange={event => setName(event.target.value)}
                           value={name}
                           className={"input-text-normal"}
                           style={{marginBottom: "1em"}}/>
                </Fragment> : ""}
            {category === 'relax' ?
                <ReactAudioPlayer src={`${course.urlAudio}`} className={"radio"} controls/>
                :
                <ReactPlayer url={`${course.urlVideo}`}
                             className={"react-player"}
                             width="auto"
                             height="75vh"
                             controls={true}/>

            }
            <p>{course.longDescription}</p>
            {edit === true ?
                <Fragment>
                    <Grid container spacing={3}>
                        {category === 'relax' ?
                            <Grid item xs={12} md={12}>
                                <label className={"input-label"}>Audio</label>
                                <input type={"file"} onChange={onChangeAudio}/>
                                {showVideoIcon()}
                            </Grid>
                            :
                            <Grid item xs={12} md={12}>
                                <label className={"input-label"}>Video</label>
                                <input type={"file"} onChange={onChangeVideo}/>
                                {showVideoIcon()}
                            </Grid>
                        }
                        <Grid item xs={12} md={12}>
                            <label className={"input-label"}>Beschreibung</label>
                            <textarea name="longDescription" rows="10" cols="30" className={"input-text-normal"}
                                      value={longDescription}
                                      onChange={event => setLongDescription(event.target.value)}>Beschreibung</textarea>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <label className={"input-label"}>Kurzbeschreibung</label>
                            <textarea name="ShortDescription" rows="10" cols="30" className={"input-text-normal"}
                                      value={shortDescription}
                                      onChange={event => setShortDescription(event.target.value)}>Kurzbeschreibung</textarea>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div>
                                <FormControlLabel
                                    control={<Switch checked={active} color={"primary"}
                                                     onChange={() => setActive(!active)}/>}
                                    label="Kurs aktiv"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button color={"primary"} variant={"outlined"} onClick={submitChanges}>Speichern</Button>
                        </Grid>
                    </Grid>
                </Fragment> : ""}
            {role === 'Admin' ? <Button onClick={() => setEdit(!edit)}>Bearbeiten</Button> : ""}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Änderungen wurden gespeichert!
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default SportsDetail;

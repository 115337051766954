import React, {useEffect, useState} from 'react';
import CourseLink from "../../../components/courseLink/courseLink";
import ModalNewCourse from "../../../utils/modal/modalNewCourse";
import {useParams} from "react-router";
import axios from "../../../axios";
import {withRouter} from 'react-router-dom';
import {Button} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";

const LecturesOverview = (props) => {
    let {id} = useParams();
    const [lectionsList, setLectionsList] = useState();
    const [displayModal, setDisplayModal] = useState(false);
    const {getAccessTokenSilently} = useAuth0();
    const {user} = useAuth0();
    const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
    const userRole = user[rolePath][0];
    const [reload, setReload] = useState(true);

    useEffect(async () => {
        const token = await getAccessTokenSilently();

        axios.get('/courses/' + id, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setLectionsList(response.data.course);
            })
            .catch(err => console.log(err))
    }, [])

    let renderLectionsList = () => {
        if (lectionsList != null) {
            return lectionsList.lections.map(ig => {
                return (<CourseLink key={ig.name} src={ig.titleImage} alt={"course-name"} name={ig.name}
                                    link={"/academy/courses/" + id + "/" + ig._id}/>)
            })
        } else {
            return <p>Waiting for server...</p>
        }
    };

    const renderButtonNew = () => {
        if (userRole === 'Admin') {
            return (
                <Button variant={"contained"} size={"large"} color={"primary"}
                        onClick={() => setDisplayModal(!displayModal)}>Neue Lektion
                </Button>
            )
        }
    };

    const changeDisplayModal = () => {
        setDisplayModal(!displayModal);
        if (displayModal === true) {
            setReload(!reload);
        }
    }


    return (
        <div>
            <ModalNewCourse display={displayModal} title={"Neue Kurskategorie"} btnContent={"speichern"}
                            btnContent2={"abbrechen"} func2={changeDisplayModal} id={id}/>
            {renderLectionsList()}
            {renderButtonNew()}
        </div>
    );
};

export default withRouter(LecturesOverview);

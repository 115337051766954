import React from 'react';

const RecipeTableHead = () => {
    return (
        <thead>
        <tr>
            <th className={"table-head-element"}>Name</th>
            <th className={"table-head-element"}>kcal</th>
            <th className={"table-head-element"}></th>
        </tr>
        </thead>
    );
};

export default RecipeTableHead;

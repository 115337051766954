import React from 'react';
import CardMaterialUi from "../../../utils/card/CardMaterialUi/cardMaterialUi";
import {Button, Grid} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import backPic from '../../../assets/images/back.jpg';
import workoutPic from '../../../assets/images/workout.png';
import bellyKillerPic from '../../../assets/images/bellyKiller.jpg';
import activeBreakPic from '../../../assets/images/activeBreak.jpg';


const SportsOveriew = (props) => {
    const {user} = useAuth0();
    const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
    const role = user[rolePath][0];
    return (
        <div>
            <Grid container spacing={3}>
                <CardMaterialUi img={backPic} title={"Rücken"} btnTxt={"Los geht's!"}
                                link={"/academy/sports/category/back"}
                                text={"Im Rückenfit wird der komplette Körper gekräftigt, mit Fokus auf der Rückenmuskulatur. "}/>
                <CardMaterialUi img={workoutPic} title={"Workout"} btnTxt={"Los geht's!"}
                                link={"/academy/sports/category/workout"}
                                text={"Ausdauer und Kraft verbessern in kurzen Einheiten von maximal 30 Minuten. "}/>
                <CardMaterialUi img={bellyKillerPic} title={"Bauchkiller"} btnTxt={"Los geht's!"}
                                link={"/academy/sports/category/bellykiller"}
                                text={"Dem Bauchspeck den Kampf ansagen, dass tun wir in diesen kurzen Einheiten. "}/>
                <CardMaterialUi img={activeBreakPic} title={"Aktive Pause"} btnTxt={"Los geht's!"}
                                link={"/academy/sports/category/activebreak"}
                                text={"In diesen kurzen Bewegungseinheiten schaffen wir einen Ausgleich zum Alltag. "}/>

            </Grid>
            {role === 'Admin' ? <Button variant={"contained"} color={"primary"} style={{marginTop: "1em"}}
                                        onClick={() => props.history.push("/academy/sports/new")}>Neuer
                Kurs</Button> : ""}
        </div>
    );
};

export default withRouter(SportsOveriew);

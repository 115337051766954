import React, {Fragment} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import RecipeNew from "../containers/academy/recipeNew/recipeNew";
import RecipeCategory from "../containers/academy/recipesCategory/recipeCategory";
import RecipeEdit from "../containers/academy/recipeEdit/recipeEdit";
import Recipe from "../layouts/recipe/recipe";
import RecipesOverview from "../containers/academy/recipesOverview/recipesOverview";
import ErrorPage from "../layouts/error/errorPage";
import {Hidden} from "@material-ui/core";
import MenuListBig from "../components/navigation/sideBar/menuList/menuListBig";
import {useAuth0} from "@auth0/auth0-react";


const RecipeRouting = () => {
    const {user} = useAuth0();
    const renderRouting = () => {
        const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
        if (user[rolePath].indexOf("Admin") > -1) {
            return (
                <Switch>
                    <Route exact path={"/academy/recipes/new"}> <RecipeNew/> </Route>
                    <Route exact path={"/academy/recipes/:id/edit"}> <RecipeEdit/> </Route>
                    <Route exact path={"/academy/recipes/:id"}> <Recipe/> </Route>
                    <Route exact path={"/academy/recipes/categories/:category"}> <RecipeCategory/></Route>
                    <Route exact path={"/academy/recipes"}><RecipesOverview/></Route>
                </Switch>
            )
        } else if (user[rolePath].indexOf("UserAll") > -1 || user[rolePath].indexOf("UserBasic") > -1) {
            return (
                <Fragment>
                    <Route exact path={"/academy/recipes/:id"}> <Recipe/> </Route>
                    <Route exact path={"/academy/recipes/categories/:category"}> <RecipeCategory/></Route>
                    <Route exact path={"/academy/recipes"}><RecipesOverview/></Route>
                </Fragment>
            )
        } else if (user[rolePath].indexOf("UserBasic") > -1) {
            return (
                <Fragment>
                    <Route exact path={"/academy/recipes"}><RecipesOverview/></Route>
                </Fragment>
            )
        } else {
            return (<Redirect to={"/academy/dashboard"}/>)
        }
    }

    return (
        <Fragment>
            <Hidden smDown>
                <div className={"menu-side-container"}>
                    <MenuListBig/>
                </div>
            </Hidden>
            <div className={'wrapper-content'}>
                {renderRouting()}
            </div>
        </Fragment>
    );
};

export default RecipeRouting;

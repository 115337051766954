import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';


import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {withRouter} from "react-router-dom";
import {Cookies} from "react-cookie";
import {Hidden} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";

import './navbar.css';
import 'react-cookie';
import * as path from "path";
import AuthButton from "../../auth-button/auth-button";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        cursor: "pointer"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const Navbar = (props) => {
    const classes = useStyles();
    const open = true;
    const cookies = new Cookies();
    const {user} = useAuth0();

    const btnLogout = () => {
        cookies.remove('token', {path: "/"});
        props.history.push('/');
    }

    const btnLogo = () => {
        props.history.push("/");
    }

    console.log(user);

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                {user === undefined ? "" :

                    <Hidden mdUp>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                                    onClick={props.funcOpen}>
                            <MenuIcon/>
                        </IconButton>
                    </Hidden>}
                <Typography variant="h6" className={classes.title} onClick={btnLogo}>
                    Ich im Einklang
                </Typography>
                <AuthButton/>
            </Toolbar>
        </AppBar>
    );
};

export default withRouter(Navbar);

import axios from 'axios';

/**
 * @description: Configuration of axios
 **/
const IP_Backend = process.env.REACT_APP_URL;
const Port = process.env.REACT_APP_PORT;
const protocol = process.env.REACT_App_PROTOCOL;
const lnk = protocol + '://' + IP_Backend + ":" + Port + '/api/v1'; //Assemble backend url
//const lnk = 'https://app-backend-test.azurewebsites.net/api/v1';
//const lnk = 'https://' + IP_Backend + '/api/v1'; //Assemble backend url

//Create axios instance
const instance = axios.create({
    baseURL: lnk
});

export default instance;

import React from 'react';
import RecipeImageComponent from "../../../components/recipeComponents/recipeImageComponent/recipeImageComponent";

const RecipeDetail = (props) => {
    const ingredients = props.recipe.ingredients.map((ig) => {
        return (
            <tr>
                <td className={"table-row-element"}>{ig.qty}</td>
                <td className={"table-row-element"}>{ig.desc}</td>
            </tr>
        )
    })

    return (
        <div className={"div-container-wide"}>
            <h1>{props.recipe.name}</h1>
            <RecipeImageComponent images={props.recipe.images}/>
            <h3>Zutaten für 4 Portionen</h3>
            <table>
                {ingredients}
            </table>
            <h3>Zubereitung</h3>
            <div>
                <p>{props.recipe.preparation}</p>
            </div>
            <h3>Abwandlung</h3>
            <div>
                <p>{props.recipe.variant}</p>
            </div>

        </div>
    );
};

export default RecipeDetail;

import React, {Fragment, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import axios from "../../../axios";
import {Button, CircularProgress} from "@material-ui/core";
import {Player} from 'video-react';
import ReactPlayer from "react-player";
import '../../../utils/video-react.css';
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    button: {
        marginRight: "1em"
    }
});

const CourseComponentVideo = (props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [status, setStatus] = useState(false);
    const [showhImage, setShowhImage] = useState(false);
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState(props.video);
    const [blobVideo, setBlobVideo] = useState(props.blobVideoName);
    const [statusUpload, setStatusUpload] = useState("empty"); //emtpy, gotContent, uploading, uploaded
    const classes = useStyles();

    const submitChange = () => {
        props.changeContent(props.nr, {urlVideo: fileUrl, blobVideo: blobVideo});
        setStatus(!status);
    };

    const submitVideo = async () => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();
        formData.append('files', file);
        setStatusUpload("uploading");
        axios.post("/assets/video", formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setFileUrl(response.data.url);
                setBlobVideo(response.data.blobName)
                setStatusUpload("uploaded");
            })
            .catch(err => console.log(err));
    };

    const onChangeVideo = (event) => {
        setFile(event.target.files[0]);
        setStatusUpload("gotContent");
    };


    const showStatusIcon = () => {
        switch (statusUpload) {
            case "empty" :
                return (<Button variant={"outlined"} size={"small"} className={classes.button} disabled={true}
                                onClick={submitVideo}>Hochladen</Button>)
                break;
            case "gotContent":
                return (<Button variant={"outlined"} size={"small"} className={classes.button} disabled={false}
                                onClick={submitVideo}>Hochladen</Button>)
                break;
            case "uploading":
                return (
                    <Fragment>
                        <Button variant={"outlined"} size={"small"} className={classes.button} disabled={true}
                                onClick={submitChange}>Hinzufügen</Button>
                        <CircularProgress size={24} style={{marginLeft: "0.5em"}}/>
                    </Fragment>)
                break;
            case "uploaded":
                return (
                    <Fragment>
                        <Button variant={"outlined"} size={"small"} className={classes.button} disabled={false}
                                onClick={submitChange}>Hinzufügen</Button>
                        <CheckCircleOutlined color={"success"} fontSize={"medium"} style={{marginLeft: "0.5em"}}/>
                    </Fragment>)
                break;
        }
    }
    const renderList = () => {
        if (status) {
            return (
                <Fragment>
                    <Player style={{width: "100%"}} src={fileUrl} alt={"..."}/>
                    <input type={"file"} onChange={onChangeVideo}/>
                    {showStatusIcon()}
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <Player style={{width: "100%"}} src={fileUrl} alt={"..."} onClick={() => setStatus(!status)}/>
                    <Button variant={"outlined"} size={"small"} onClick={() => setStatus(!status)}>Video
                        ändern </Button>
                    <br/>
                </Fragment>
            )
        }
    }

    return (
        <Fragment>
            {renderList()}
        </Fragment>
    );
};

export default CourseComponentVideo;

import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link} from "react-router-dom";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import NotListedLocationOutlinedIcon from '@material-ui/icons/NotListedLocationOutlined';
import {
    AccountBoxOutlined,
    SchoolOutlined,
    FitnessCenterOutlined,
    LocalCafeOutlined,
    LocalDining
} from "@material-ui/icons";


const NavItem = (props) => {

    const chooseItem = (name) => {
        switch (name) {
            case "home":
                return (<HomeOutlinedIcon fontSize={"large"}/>);
            case "recipe":
                return (<AssignmentOutlinedIcon fontSize={"large"}/>);
            case "academy":
                return (<SchoolOutlined fontSize={"large"}/>);
            case "user":
                return (<AccountBoxOutlined fontSize={"large"}/>);
            case "fitness":
                return (<FitnessCenterOutlined fontSize={"large"}/>);
            case "relax":
                return (<LocalCafeOutlined fontSize={"large"}/>);
            case "spoon":
                return (<LocalDining fontSize={"large"}/>)
            default:
                return (<NotListedLocationOutlinedIcon fontSize={"large"}/>);
        }
    }

    return (
        <ListItem button component={Link} to={props.target}>
            <ListItemIcon>{chooseItem(props.icon)}</ListItemIcon>
            <ListItemText primary={props.text}/>
        </ListItem>
    );
};

export default NavItem;

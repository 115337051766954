import React from 'react';


const IngredientsList = (props) => {
    let renderIngredients = props.ingList.map((ig, id) => {
        return (<tr key={id}>
            <td>{ig.qty}</td>
            <td>{ig.desc}</td>
        </tr>)
    });


    return (
        <table>
            <tbody>
            {renderIngredients}
            </tbody>
        </table>
    );
};

export default IngredientsList;

import React, {Fragment, useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
import CardComponent from "../../../utils/card/card";
import axios from "../../../axios";
import {useAuth0} from "@auth0/auth0-react";
import EmotionBox from "../../../components/emotionBox/emotionBox";

const RecipesOverview = (props) => {
    const [recipesOveriewItems, setRecipesOverviewItems] = useState([]);
    const {user} = useAuth0();
    const {getAccessTokenSilently} = useAuth0();

    useEffect(async () => {
        const token = await getAccessTokenSilently({});
        axios.get('/categories', {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setRecipesOverviewItems(response.data.courses); //Write orders into state
            })
            .catch(err => console.log(err)) //Throw error to console
    }, [])

    const redirectUser = (linkTarget) => {
        props.history.push("/academy/recipes/categories/" + linkTarget)
    }

    const gridItems = recipesOveriewItems.map((ig) => {
        return (
            <Grid key={ig.name} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <CardComponent head={ig.name} text={ig.description} imgSrc={ig.image.imageUrl}
                               onClickHandler={() => redirectUser(ig._id)}/>
            </Grid>
        )
    })


    const renderGridItems = () => {
        const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
        if (user[rolePath].indexOf("Admin") > -1) {
            return gridItems;
        }
    }


    return (
        <Fragment>
            <EmotionBox type={"recipe"} externLink={true}/>
            <Grid container spacing={3}>
                {renderGridItems()}
            </Grid>
        </Fragment>
    );
};

export default withRouter(RecipesOverview);

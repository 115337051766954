import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {CookiesProvider} from 'react-cookie';
import './App.css';

import Landing from "./layouts/landing/landing";
import AcademyLanding from "./containers/academy/academyLanding/academyLanding";
import RecipeRouting from "./routing/recipeRouting";
import CourseRouting from "./routing/courseRouting";
import Navigation from "./containers/navigation/navigation";
import {purple} from "@material-ui/core/colors";
import ComicNeue from "./assets/fonts/comic-neue-v2-latin-regular.woff2";
import {CssBaseline} from "@material-ui/core";
import Auth0ProviderWithHistory from "./authentication/auth0-provider-with-history";
import ProtectedRoute from "./authentication/protected-route";
import SportRouting from "./routing/sportsRouting";
import RelaxRouting from "./routing/relaxRouting";
import Impressum from "./layouts/legals/impressum/impressum";
import Privacy from "./layouts/legals/privacy/privacy";
import TalkRouting from "./routing/talkRouting";

const comicneue = {
    fontFamily: 'ComicNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('ComicNeue'),
    local('ComicNEue-Regular'),
    url(${ComicNeue}) format('woff2')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2a6da4',
        },
        secondary: {
            main: '#aad5dd',
        },
    },
    typography: {
        fontFamily: 'ComicNeue, Arial',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [comicneue],
            },
        },
    }
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        marginTop: "64px"
    },
    wrapper: {
        width: '100%',
        boxSizing: "border-box"
    }

}));

function App() {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <CookiesProvider>
                <BrowserRouter>
                    <Auth0ProviderWithHistory>
                        <div className={classes.root}>
                            <Navigation/>
                            <main className={classes.content}>
                                <div className={classes.toolbar}>
                                    <div id={"wrapper"} className={classes.wrapper}>
                                        <Switch>
                                            <Route path={"/"} exact component={Landing}/>
                                            <ProtectedRoute exact path={"/academy/dashboard"}
                                                            component={AcademyLanding}/>
                                            <ProtectedRoute path={"/academy/recipes/"} component={RecipeRouting}/>
                                            <ProtectedRoute path={"/academy/courses/"} component={CourseRouting}/>
                                            <ProtectedRoute path={"/academy/sports"} component={SportRouting}/>
                                            <ProtectedRoute path={"/academy/relax"} component={RelaxRouting}/>
                                            <ProtectedRoute path={"/academy/talk"} component={TalkRouting}/>
                                            <Route exact path={"/impressum"} component={Impressum}/>
                                            <Route exact path={"/privacy"} component={Privacy}/>
                                            <Route exact path={"*"}><h1>Error</h1></Route>
                                        </Switch>
                                    </div>

                                </div>
                            </main>
                        </div>
                    </Auth0ProviderWithHistory>
                </BrowserRouter>
            </CookiesProvider>
        </ThemeProvider>
    );
}

export default App;

import React, {Fragment} from 'react';
import CourseComponentText from "../courseComponentText/courseComponentText";
import CourseComponentHeadline from "../courseComponentHeadline/courseComponentHeadline";
import '../courseComponents.css';
import CourseComponentImage from "../courseComponentImage/courseComponentImage";
import CourseComponentVideo from "../courseComponentVideo/courseComponentVideo";
import CourseComponentPdf from "../courseComponentPdf/CourseComponentPdf";

const CourseComponentSummary = (props) => {
    let render = props.cont.map((ig, index) => {
        let returnValue;
        switch (ig.type) {
            case "headline1":
                returnValue = (<CourseComponentHeadline text={ig.body} key={index} nr={index}
                                                        changeContent={props.changeContent}/>)
                break;
            case "text":
                returnValue = (
                    <CourseComponentText key={index} changeContent={props.changeContent} nr={index} text={ig.body}/>)

                // falls expression mit value2 übereinstimmt
                break;
            case "image":
                returnValue = (
                    <CourseComponentImage key={index} image={ig.body.urlImage} nr={index}
                                          changeContent={props.changeContent}/>);
                break;
            case "video":
                returnValue = (
                    <CourseComponentVideo key={index} video={ig.body.urlVideo} nr={index}
                                          changeContent={props.changeContent}/>
                )
                break;
            case "pdf":
                returnValue = (
                    <CourseComponentPdf key={index} pdf={ig.body.urlPdf} nr={index}
                                        changeContent={props.changeContent}/>
                )
                break;
            default:
                // Anweisungen werden ausgeführt,
                // falls keine der case-Klauseln mit expression übereinstimmt
                break;
        }
        return returnValue;
    })
    return (
        <Fragment>
            {render}
        </Fragment>
    );
};

export default CourseComponentSummary;

import React, {useEffect, useState} from 'react';
import CourseLink from "../../../components/courseLink/courseLink";
import ModalNewCategory from "../../../utils/modal/modalNewCategory";
import axios from "../../../axios";
import {Button} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";

const CourseList = () => {
    const [displayModal, setDisplayModal] = useState(false);
    const [courseList, setCourseList] = useState();
    const [reload, setReload] = useState(true);
    const {getAccessTokenSilently} = useAuth0();
    const {user} = useAuth0();
    const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
    const userRole = user[rolePath][0];

    useEffect(async () => {
        const token = await getAccessTokenSilently();
        axios.get('/courses/', {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setCourseList(response.data.courses);
            })
            .catch(err => console.log(err))
    }, [reload])

    const changeDisplayModal = () => {
        setDisplayModal(!displayModal);
        if (displayModal === true) {
            setReload(!reload);
        }
    }

    const showCourses = () => {
        if (courseList != null) {
            let renderCourseList = courseList.map((ig) => {
                return (<CourseLink key={ig.name} src={ig.urlImage} alt={"course-name"} name={ig.name}
                                    link={"/academy/courses/" + ig._id}/>)
            })
            return renderCourseList;
        } else {
            return (<p>Waiting for server...</p>)
        }
    }

    const renderButtonNewCourse = () => {
        if (userRole === 'Admin') {
            return (
                <Button variant={"contained"} size={"large"} color={"primary"}
                        onClick={() => setDisplayModal(!displayModal)}>Neuer Kurs
                </Button>
            )
        }
    }

    return (
        <div>
            <ModalNewCategory display={displayModal} title={"Neue Kurskategorie"} btnContent={"speichern"}
                              btnContent2={"abbrechen"} func2={changeDisplayModal}/>
            {showCourses()}
            {renderButtonNewCourse()}
        </div>
    );
};

export default CourseList;
import React, {useState, Fragment} from 'react';
import CourseComponentSummary from "../../../components/courseComponents/courseComponentSummary/courseComponentSummary";
import {useParams} from "react-router-dom";
import {Container} from "@material-ui/core";
import '../../../components/courseComponents/courseComponents.css';
import axios from "../../../axios";
import {withRouter} from 'react-router-dom';
import {useAuth0} from "@auth0/auth0-react";

const CourseDetailNew = (props) => {
    const {getAccessTokenSilently} = useAuth0();

    let {id} = useParams();

    const [content, setContent] = useState([]);
    const [rerender, setRerender] = useState(0);
    const [lectureName, setLectureName] = useState("");

    const [showTextArea, setShowTextArea] = useState(false);
    const [showHeadline, setShowHeadline] = useState(false);

    const [text, setText] = useState("");

    const addToContent = (tp, bdy) => {
        let inhalt = content;
        let data = {
            type: tp,
            body: bdy
        }
        inhalt.push(data);
        setContent(inhalt);
        setText("");
        setShowHeadline(false);
        setShowTextArea(false);
        setRerender(rerender + 1);
    };

    const changeContent = (ind, text) => {
        let inhalt = content;
        inhalt[ind].body = text;
        setContent(inhalt);
        console.log(content);
        setRerender(rerender + 1);
    }

    const submitCourse = async () => {
        const token = await getAccessTokenSilently();

        const data = {
            name: lectureName,
            content: content
        }

        axios.post('/courses/' + id + '/lections', data, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                console.log(response);
                props.history.push('/academy/courses/' + id + '/' + response.data.lecture._id);
            })
            .catch(err => console.log(err))
    }

    let newItem;
    if (showHeadline) {
        newItem = (
            <Fragment>
                <input className={"course-headline"} type={"text"} value={text}
                       onChange={event => setText(event.target.value)}/>
                <button className={"course-button-small"} onClick={() => addToContent("headline1", text)}>add</button>
            </Fragment>
        )
    } else if (showTextArea) {
        newItem = (
            <Fragment>
                <textarea className={"course-text"} name="message" rows="10" cols="30"
                          onChange={event => setText(event.target.value)}> </textarea>
                <button className={"course-button-small"} onClick={() => addToContent("text", text)}>add</button>
            </Fragment>
        )
    } else {
        newItem = (
            <div>
                <button className={"course-button-normal"} onClick={() => setShowHeadline(true)}>headline</button>
                <button className={"course-button-normal"} onClick={() => setShowTextArea(true)}>text</button>
                <button className={"course-button-normal"} onClick={submitCourse}>Submit</button>
            </div>
        )
    }

    return (
        <Container maxWidth={"lg"}>
            <input type={"text"} className={"input-text-normal"} onChange={event => setLectureName(event.target.value)}
                   value={lectureName}/>
            {content.length > 0 ? <CourseComponentSummary cont={content} changeContent={changeContent}/> :
                <p>Keine Einträge vorhanden</p>}
            {newItem}

        </Container>
    );
};

export default withRouter(CourseDetailNew);

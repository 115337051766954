import React, {useEffect, useState} from 'react';
import axios from "../../../axios";
import {useAuth0} from "@auth0/auth0-react";
import CardMaterialUi from "../../../utils/card/CardMaterialUi/cardMaterialUi";
import {Button, Grid} from "@material-ui/core";

const RelaxOverview = (props) => {
    const {getAccessTokenSilently} = useAuth0();
    const {user} = useAuth0();
    const [courses, setCourses] = useState([]);
    const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
    const role = user[rolePath][0];

    const getPermission = () => {
        if (role === 'Admin') {
            return 'admin';
        } else {
            return 'user';
        }
    }


    useEffect(async () => {
        const token = await getAccessTokenSilently();
        const perm = getPermission();
        axios.get(`/sports/${perm}/?sub=relax`, {headers: {Authorization: `Bearer ${token}`}})
            .then(result => {
                setCourses(result.data.result);
            })
            .catch(err => console.log(err.error))

    }, [])

    const renderSportCourese = () => {
        const perm = getPermission();
        return courses.map((ig) => {
            return (<CardMaterialUi img={ig.urlImage} title={ig.name} text={ig.shortDescription}
                                    link={`/academy/relax/${ig._id}`} btnTxt={"Los geht's!"}/>)
        })

    }

    return (
        <div>
            <Grid container spacing={3}>
                {renderSportCourese()}

            </Grid>
            {role === 'Admin' ? <Button variant={"contained"} color={"primary"} style={{marginTop: "1em"}}
                                        onClick={() => props.history.push("/academy/sports/new")}>Neuer
                Kurs</Button> : ""}
        </div>
    );
};

export default RelaxOverview;

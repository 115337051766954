import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MenuList from "./menuList/menuList";


const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function SideBarMobil(props) {
    const classes = useStyles();


    return (
        <div>

            <React.Fragment>

                <Drawer anchor={'left'} open={props.open} onClose={props.toggle}>
                    <MenuList/>
                </Drawer>
            </React.Fragment>

        </div>
    );
}
import React, {Fragment, useState} from 'react';
import {Button} from "@material-ui/core";
import axios from "../../../axios";
import {useAuth0} from "@auth0/auth0-react";


const CourseComponentImage = (props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [status, setStatus] = useState(false);
    const [showhImage, setShowhImage] = useState(false);
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState(props.image);
    const [blobImage, setBlobImage] = useState(props.blobImageName);

    const submitChange = () => {
        props.changeContent(props.nr, {urlImage: fileUrl, blobImage: blobImage});
        setStatus(!status);
    };

    const submitImage = async () => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();
        formData.append('files', file);

        axios.post("/assets/image", formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setFileUrl(response.data[0].url);
                setBlobImage(response.data[0].blobName)
            })
            .catch(err => console.log(err));
    };

    const onChangeImage = (event) => {
        setFile(event.target.files[0]);
    };

    const renderList = () => {
        if (status) {
            return (
                <Fragment>
                    <img style={{width: "100%"}} src={fileUrl} alt={"..."}/>
                    <input type={"file"} onChange={onChangeImage}/>
                    <Button variant={"outlined"} size={"small"} onClick={submitImage}>hochladen</Button>
                    <Button variant={"outlined"} size={"small"} onClick={submitChange}>Speichern</Button>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <img style={{width: "100%"}} src={fileUrl} alt={"..."} onClick={() => setStatus(!status)}/>
                </Fragment>
            )
        }
    }

    return (
        <Fragment>
            {renderList()}
        </Fragment>
    );
};

export default CourseComponentImage;

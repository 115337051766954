import React, {Fragment} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Redirect, Route, Switch} from "react-router-dom";
import SportsNew from "../containers/sports/sportsNew/sportsNew";
import SportsDetail from "../containers/sports/sportsDetail/sportsDetail";
import RelaxOverview from "../containers/academy/relax/relaxOverview";
import {Hidden} from "@material-ui/core";
import MenuListBig from "../components/navigation/sideBar/menuList/menuListBig";
import TalkNew from "../containers/talk/talkNew/talkNew";
import TalkOverview from "../containers/talk/talkOverview/talkOverview";
import TalkDetail from "../containers/talk/talkDetail/talkDetail";


const TalkRouting = () => {
    const {user} = useAuth0();
    const renderRouting = () => {
        const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
        if (user[rolePath].indexOf("Admin") > -1) {
            return (
                <Switch>
                    <Route path={"/academy/talk/new"} component={TalkNew}/>
                    <Route exact path={"/academy/talk/:id"} component={TalkDetail}/>
                    <Route exact path={"/academy/talk"} component={TalkOverview}/>
                </Switch>
            )
        } else if (user[rolePath].indexOf("UserAll") > -1 || user[rolePath].indexOf("UserSport") > -1) {
            return (
                <Switch>
                    <Route exact path={"/academy/talk/:id"} component={TalkDetail}/>
                    <Route exact path={"/academy/talk"} component={TalkOverview}/>
                </Switch>
            )
        } else {
            return (<Redirect to={"/academy/dashboard"}/>)
        }
    }

    return (
        <Fragment>
            <Hidden smDown>
                <div className={"menu-side-container"}>
                    <MenuListBig/>
                </div>
            </Hidden>
            <div className={'wrapper-content'}>
                {renderRouting()}
            </div>
        </Fragment>
    );
};


export default TalkRouting;

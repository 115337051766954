import React, {useEffect, useState} from 'react';
import axios from "../../../axios";
import {useAuth0} from "@auth0/auth0-react";
import {useParams} from "react-router-dom";
import CardMaterialUi from "../../../utils/card/CardMaterialUi/cardMaterialUi";
import {Grid} from "@material-ui/core";

const SportsOverviewCategory = (props) => {
    let {category} = useParams();
    const {getAccessTokenSilently} = useAuth0();
    const {user} = useAuth0();
    const [courses, setCourses] = useState([]);
    const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
    const role = user[rolePath][0];

    const getPermission = () => {
        if (role === 'Admin') {
            return 'admin';
        } else if (role === 'UserBasic' || role === 'UserPremium') {
            return 'user';
        }
    }


    useEffect(async () => {
        const token = await getAccessTokenSilently();
        const perm = getPermission();
        axios.get(`/sports/${perm}/?sub=${category}`, {headers: {Authorization: `Bearer ${token}`}})
            .then(result => {
                setCourses(result.data.result);
            })
            .catch(err => console.log(err.error))

    }, [])

    const renderSportCourese = () => {
        const perm = getPermission();
        return courses.map((ig) => {
            return (<CardMaterialUi img={ig.urlImage} title={ig.name} text={ig.shortDescription}
                                    link={`/academy/sports/${ig._id}`} btnTxt={"Los geht's!"}/>)
        })

    }

    return (
        <Grid container spacing={3}>
            {renderSportCourese()}
        </Grid>
    );
};

export default SportsOverviewCategory;

import React, {Fragment, useState} from 'react';

const CourseComponentHeadline = (props) => {
    const [status, setStatus] = useState(false);
    const [text, setText] = useState(props.text);

    const submitChange = () => {
        props.changeContent(props.nr, text);
        setStatus(!status);
    };

    let renderItem;
    if (status) {
        renderItem = (
            <Fragment>
                <input type={"text"} onChange={event => setText(event.target.value)} value={text}
                       className={"course-headline"}/>
                <button onClick={submitChange} className={"course-button-small"}>speichern</button>
            </Fragment>
        )
    } else {
        renderItem = (<h2 className={"course-headline"} onClick={() => setStatus(!status)}>{props.text}</h2>);
    }


    return (
        <Fragment>
            {renderItem}
        </Fragment>
    );
};

export default CourseComponentHeadline;

import React, {Fragment, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import axios from "../../../axios";
import {Button, CircularProgress} from "@material-ui/core";
import '../../../utils/video-react.css';
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    button: {
        marginRight: "1em"
    }
});

const CourseComponentPdf = (props) => {
    const {getAccessTokenSilently} = useAuth0();
    const [status, setStatus] = useState(false);
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState(props.pdf);
    const [blobPdf, setBlobPdf] = useState(props.blobPdfName);
    const [statusUpload, setStatusUpload] = useState("empty"); //emtpy, gotContent, uploading, uploaded
    const classes = useStyles();

    const submitChange = () => {
        props.changeContent(props.nr, {urlPdf: fileUrl, blobPdf: blobPdf});
        setStatus(!status);
    };

    const submitPdf = async () => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();
        formData.append('files', file);
        setStatusUpload("uploading");
        axios.post("/assets/pdf", formData, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setFileUrl(response.data.url);
                setBlobPdf(response.data.blobName)
                setStatusUpload("uploaded");
            })
            .catch(err => console.log(err));
    };

    const onChangePdf = (event) => {
        setFile(event.target.files[0]);
        setStatusUpload("gotContent");
    };


    const showStatusIcon = () => {
        switch (statusUpload) {
            case "empty" :
                return (<Button variant={"outlined"} size={"small"} className={classes.button} disabled={true}
                                onClick={submitPdf}>Hochladen</Button>)
                break;
            case "gotContent":
                return (<Button variant={"outlined"} size={"small"} className={classes.button} disabled={false}
                                onClick={submitPdf}>Hochladen</Button>)
                break;
            case "uploading":
                return (
                    <Fragment>
                        <Button variant={"outlined"} size={"small"} className={classes.button} disabled={true}
                                onClick={submitChange}>Hinzufügen</Button>
                        <CircularProgress size={24} style={{marginLeft: "0.5em"}}/>
                    </Fragment>)
                break;
            case "uploaded":
                return (
                    <Fragment>
                        <Button variant={"outlined"} size={"small"} className={classes.button} disabled={false}
                                onClick={submitChange}>Hinzufügen</Button>
                        <CheckCircleOutlined color={"success"} fontSize={"medium"} style={{marginLeft: "0.5em"}}/>
                    </Fragment>)
                break;
        }
    }
    const renderList = () => {
        if (status) {
            return (
                <Fragment>
                    <Button variant={"contained"} onClick={() => window.open(fileUrl)}>PDF öffnen</Button>
                    <input type={"file"} onChange={onChangePdf}/>
                    {showStatusIcon()}
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <Button variant={"contained"} onClick={() => window.open(fileUrl)}>PDF öffnen</Button>
                    <Button variant={"outlined"} size={"small"} style={{marginLeft: '0.5em'}}
                            onClick={() => setStatus(!status)}>PDF ändern </Button>
                    <br/>
                </Fragment>
            )
        }
    }

    return (
        <Fragment>
            {renderList()}
        </Fragment>
    );
};

export default CourseComponentPdf;

import React from 'react';
import RecipeTableHead from "../../../components/recipe/overviewTable/recipeTableHead/recipeTableHead";
import RecipeTableRow from "../../../components/recipe/overviewTable/recipeTableRow/recipeTableRow";

const RecipeTable = (props) => {

    const tableContent = props.recipes.map((ig) => {
        return (<RecipeTableRow key={ig._id} name={ig.name} kcal={ig.kcal} id={ig._id}/>)
    })

    return (
        <table className={"table-wide"}>
            <RecipeTableHead/>
            <tbody>
            {tableContent}
            </tbody>
        </table>
    );
};

export default RecipeTable;

import React, {Fragment} from 'react';
import CourseDetailNew from "../containers/academy/courseDetailNew/courseDetailNew";
import CourseDetail from "../containers/academy/courseDetail/courseDetail";
import CourseDetailEdit from "../containers/academy/courseDetailEdit/courseDetailEdit";
import CourseOverview from "../containers/academy/courseOverview/lecturesOverview";
import CourseList from "../containers/academy/courseList/courseList";
import {Hidden} from "@material-ui/core";
import MenuListBig from "../components/navigation/sideBar/menuList/menuListBig";
import {Redirect, Route} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";


const CourseRouting = () => {
    const {user} = useAuth0();
    const renderRouting = () => {
        const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
        if (user[rolePath].indexOf("Admin") > -1) {
            return (
                <Fragment>
                    <Route exact path={"/academy/courses/:id/new"}> <CourseDetailNew/> </Route>
                    <Route exact path={"/academy/courses/:id/:coursePartId"}> <CourseDetail/> </Route>
                    <Route exact path={"/academy/courses/:id/:coursePartId/edit"}> <CourseDetailEdit/> </Route>
                    <Route exact path={"/academy/courses/:id"}> <CourseOverview/> </Route>
                    <Route exact path={"/academy/courses"}><CourseList/></Route>
                </Fragment>
            )
        } else if (user[rolePath].indexOf("UserAll") > -1 || user[rolePath].indexOf("UserNutrition") > -1) {
            return (
                <Fragment>
                    <Route exact path={"/academy/courses/:id/:coursePartId"}> <CourseDetail/> </Route>
                    <Route exact path={"/academy/courses/:id"}> <CourseOverview/> </Route>
                    <Route exact path={"/academy/courses"}><CourseList/></Route>
                </Fragment>
            )
        } else {
            return (<Redirect to={"/academy/dashboard"}/>)
        }
    }


    return (
        <Fragment>
            <Hidden smDown>
                <div className={"menu-side-container"}>
                    <MenuListBig/>
                </div>
            </Hidden>
            <div className={'wrapper-content'}>
                {renderRouting()}
            </div>
        </Fragment>
    );
};

export default CourseRouting;

import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import RecipeTable from "../recipeTable/recipeTable";
import axios from "../../../axios";
import {withRouter} from "react-router-dom";
import {Button} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";

const RecipeCategory = (props) => {
    let {category} = useParams();
    const {user} = useAuth0();
    const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
    const userRole = user[rolePath][0];

    const [recipeObject, setRecipObject] = useState([]);
    const {getAccessTokenSilently} = useAuth0();

    useEffect(async () => {
        const token = await getAccessTokenSilently();
        let requestUrl = '/categories/' + category;

        if (userRole === 'Admin') {
            requestUrl += "/admin";
        }

        axios.get(requestUrl, {headers: {Authorization: `Bearer ${token}`}})
            .then(response => {
                setRecipObject(response.data.result);
            })
            .catch(err => console.log(err)) //Throw error to console
    }, [])


    let renderList = () => {
        if (recipeObject.recipes != null) {
            return (<RecipeTable recipes={recipeObject.recipes}/>)
        } else {
            <p>waiting for server... </p>
        }
    }

    const goToNewRecipe = () => {
        props.history.push("/academy/recipes/new?category=" + category);
    }

    return (
        <div>
            <h1>{recipeObject.name}</h1>
            {renderList()}
            {userRole === 'Admin' ? <Button variant={"contained"} color={"primary"} size={"large"}
                                            onClick={goToNewRecipe}>new</Button> : ''}
        </div>
    );
};

export default withRouter(RecipeCategory);

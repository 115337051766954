import React, {Fragment} from 'react';
import {Grid, Hidden} from "@material-ui/core";
import MenuListBig from "../../../components/navigation/sideBar/menuList/menuListBig";
import CardMaterialUi from "../../../utils/card/CardMaterialUi/cardMaterialUi";
import nutritionImage from '../../../assets/images/nutrition.jpg';
import relaxImage from '../../../assets/images/relax.jpg';
import sportImage from '../../../assets/images/sport.jpg';
import talkImage from '../../../assets/images/talk.png';
import {useAuth0} from "@auth0/auth0-react";
import NavItem from "../../../components/navigation/sideBar/navItem/navItem";
import EmotionBox from "../../../components/emotionBox/emotionBox";

const AcademyLanding = () => {

    const {user} = useAuth0();

    const renderList = () => {
        const rolePath = process.env.REACT_APP_AUTH0_AUDIENCE + '/roles'
        if (user[rolePath].indexOf("Admin") > -1 || user[rolePath].indexOf("UserAll") > -1) {
            return (
                <Fragment>
                    <CardMaterialUi img={nutritionImage} title={"Rezepte"} text={""}
                                    link={`/academy/recipes`} btnTxt={"Los geht's!"}/>
                    <CardMaterialUi img={talkImage} title={"Ernährungsvorträge"} text={""}
                                    link={`/academy/talk`} btnTxt={"Los geht's!"}/>
                    <CardMaterialUi img={sportImage} title={"Sport"} text={""}
                                    link={`/academy/sports`} btnTxt={"Los geht's!"}/>
                    <CardMaterialUi img={relaxImage} title={"Entspannung"} text={""}
                                    link={'/academy/relax'} btnTxt={"Los geht's!"}/>
                </Fragment>
            )
        } else if (user[rolePath].indexOf("UserNutrition") > -1) {
            return (
                <Fragment>
                    <CardMaterialUi img={talkImage} title={"Ernährungsvorträge"} text={""}
                                    link={`/academy/talk`} btnTxt={"Los geht's!"}/>
                </Fragment>
            )
        } else if (user[rolePath].indexOf("UserBasic") > -1) {
            return (
                <Fragment>
                    <CardMaterialUi img={nutritionImage} title={"Rezepte"} text={""}
                                    link={`/academy/recipes`} btnTxt={"Los geht's!"}/>
                    <CardMaterialUi img={sportImage} title={"Sport"} text={""}
                                    link={`/academy/sports`} btnTxt={"Los geht's!"}/>
                    <CardMaterialUi img={relaxImage} title={"Entspannung"} text={""}
                                    link={'/academy/relax'} btnTxt={"Los geht's!"}/>
                </Fragment>
            )
        }
    }


    return (
        <Fragment>
            <div>
                <Hidden smDown>
                    <div className={"menu-side-container"}>

                        <MenuListBig/>
                    </div>
                </Hidden>
                <div className={"wrapper-content"}>
                    <EmotionBox type={"landing"} externLink={true}/>
                    <Grid container spacing={3}>
                        {renderList()}
                    </Grid>
                </div>

            </div>
        </Fragment>
    );
};

export default AcademyLanding;

import React from 'react';
import Footer from "../../containers/footer/footer";
import background from '../../assets/images/emotionNew.png';
import {makeStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import './landing.css';
import {useAuth0} from "@auth0/auth0-react";
import emotionSmall from '../../assets/images/emotionSmall.jpg';
import emotion from '../../assets/images/emotionNew.png';
import {Grid} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    gridBackground: {
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "100vh"
    },
    gridContainer: {
        minHeight: "100vh"
    },
    icon: {
        color: "white",
        backgroundColor: "purple",
        borderRadius: "100%",
        padding: "20px",
        height: "60px",
        width: "60px"
    },
    rootGridContainer: {
        margin: "-24px"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }

}));


const Landing = (props) => {
    const {isAuthenticated} = useAuth0();

    if (isAuthenticated) {
        props.history.push("/academy/dashboard");
    } else {
        //continue
    }

    const classes = useStyles();


    return (
        <div>
            <div>
                <img src={emotionSmall} alt={"Emotion Pic"} className={"emotionSmall"}/>
                <img src={emotion} alt={"Emotion Pic"} className={"emotionBig"}/>
            </div>
            <Footer/>
        </div>
    );
};

export default withRouter(Landing);
